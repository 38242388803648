import {Button, Text} from '@smart/react-components';
import {TextInput} from '@smart/react-components';
import {jsonSafeParse} from '@smart/utils';
import clsx from 'clsx';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {MarketConfigContext} from '../../../context/MarketConfigContext';
import {useAuth} from '../../../context/RegisterAuthContext';
import {searchAccount} from '../../../utils/api/search-account';
import {sendOtpCode} from '../../../utils/api/send-otp-code';
import type {BooleanToggle} from '../../../utils/api/types';
import styles from './EmailEntry.module.scss';

interface EmailEntryProps {
  email: string;
  setEmail: (email: string) => void;
  setStep: (step: number) => void;
  translations: {[key: string]: string};
  contentFragment: {[key: string]: string};
}

const baseClass = `email-entry`;
const emailRegexPattern =
  /^([A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+)@([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(\.[a-zA-Z]{2,})?$/;

const EmailEntry: React.FC<EmailEntryProps> = ({
  email,
  setEmail,
  setStep,
  translations,
  contentFragment,
}) => {
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const marketConfig = React.useContext(MarketConfigContext);

  const {setAuth} = useAuth();

  const navigate = useNavigate();

  const checkEmail = () => {
    const isEmailValid = emailRegexPattern.test(email);
    setIsEmailInvalid(!isEmailValid);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === `Enter`) {
      checkEmail();
    }
  };

  const continueToNextStep = async () => {
    if (isEmailInvalid) return;

    const isEmailValid = emailRegexPattern.test(email);
    if (!isEmailValid) {
      setIsEmailInvalid(!isEmailValid);
      return;
    }

    const preview = jsonSafeParse(
      localStorage.getItem(`preview`) || `false`,
    ) as BooleanToggle;

    try {
      setIsLoading(true);
      const res = await searchAccount({email, preview});

      // Account found - redirecting to /login
      if (res.totalCount === 1) {
        navigate(`/login`);
        return;
      }

      // Account not found - send opt token and continuing to next step
      const token = await sendOtpCode({
        lang: marketConfig.language,
        email,
        preview,
      });

      setAuth({
        vToken: token.vToken,
      });
      setStep(2);
    } catch (error) {
      console.error(`Error during account search/OTP:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles[`${baseClass}`]}>
      <Text as="h2" variant="hl-200" className={styles[`${baseClass}__title`]}>
        {contentFragment.emailInputTitleLabel}
      </Text>

      <Text
        variant="p-200"
        className={styles[`${baseClass}__subtitle`]}
        as={`div`}
      >
        {contentFragment.emailInputDescriptionLabel}
      </Text>

      <TextInput
        className={clsx(styles[`${baseClass}__email`], {
          [styles[`${baseClass}__invalid`]!]: isEmailInvalid,
        })}
        autoComplete="email"
        value={email}
        label={translations.emailAddress!}
        id="email"
        error={isEmailInvalid ? translations.emailAddressError : ``}
        invalid={isEmailInvalid}
        onChange={(e) => setEmail(e.target.value)}
        onBlur={checkEmail}
        onKeyDown={handleKeyDown}
      />

      <Button
        variant="primary"
        mode={300}
        className={styles[`${baseClass}__button`]}
        onClick={continueToNextStep}
        disabled={isLoading}
        loading={isLoading}
      >
        {contentFragment.continueButtonLabel}
        <Button.Spinner />
      </Button>
    </div>
  );
};

export default EmailEntry;
