import type {Language, MarketId} from '@smart/types';
import type {RegistrationBody} from '../../lambda/post-registration';

export type BooleanToggle = 'true' | 'false';
interface VerifyOtpCodeOptions {
  readonly language: Language;
  readonly marketId: MarketId;
  readonly preview: BooleanToggle;
}

export async function postRegistration(
  options: VerifyOtpCodeOptions,
  body: RegistrationBody,
): Promise<any> {
  const {language, marketId, preview} = options;

  const envName = process.env.ENV_NAME;

  if (!envName) {
    throw new Error(`Missing "envName"`);
  }

  const searchParams = new URLSearchParams({
    envName,
    preview,
    language,
    marketId,
  });

  const signal = {
    method: `POST`,
    body: JSON.stringify(body),
  };

  const url = `${
    process.env.BASE_BFF_URL
  }/post-registration?${searchParams.toString()}`;
  const response = await fetch(url, signal);

  if (!response.ok) {
    throw new Error(`Registration failed`);
  }

  return response.json();
}
