import {Button, Spacer, Text, Toast} from '@smart/react-components';
import {jsonSafeParse} from '@smart/utils';
import clsx from 'clsx';
import React, {useState} from 'react';
import {MarketConfigContext} from '../../../context/MarketConfigContext';
import {useAuth} from '../../../context/RegisterAuthContext';
import {sendOtpCode} from '../../../utils/api/send-otp-code';
import type {BooleanToggle} from '../../../utils/api/types';
import {verifyOtpCode} from '../../../utils/api/verify-otp-code';
import CodeInput from '../../CodeInput/CodeInput';
import styles from './EmailVerification.module.scss';

interface EmailVerificationProps {
  email: string;
  setStep: (step: number) => void;
  translations: {[key: string]: string};
  contentFragment: {[key: string]: string};
}

const baseClass = `email-verification`;

const EmailVerification: React.FC<EmailVerificationProps> = ({
  email,
  setStep,
  translations,
  contentFragment,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCodeInvalid, setIsCodeInvalid] = useState(false);
  const [otp, setOtp] = useState(``);
  const [isLoading, setIsLoading] = useState(false);

  const {vToken, setAuth} = useAuth();

  const marketConfig = React.useContext(MarketConfigContext);

  const continueToNextStep = async () => {
    if (otp === ``) {
      setIsCodeInvalid(true);
      return;
    }
    const preview = jsonSafeParse(
      localStorage.getItem(`preview`) || `false`,
    ) as BooleanToggle;

    try {
      setIsLoading(true);

      await verifyOtpCode({
        code: otp,
        vToken: vToken!,
        preview,
      });

      setStep(3);
    } catch (error) {
      setIsCodeInvalid(true);
      console.error(`Error during verify Otp code`, error);
    } finally {
      setIsLoading(false);
    }
  };

  const resendCode = async () => {
    const preview = jsonSafeParse(
      localStorage.getItem(`preview`) || `false`,
    ) as BooleanToggle;

    const token = await sendOtpCode({
      lang: marketConfig.language,
      email,
      preview,
    });

    setAuth({
      vToken: token.vToken,
    });

    setIsOpen(true);
  };

  const editEmail = () => {
    setStep(1);
  };

  const handleCodeInputChange = (value: string) => {
    setOtp(value);
    setIsCodeInvalid(false);
  };

  return (
    <Toast.Provider>
      <div
        className={clsx(styles[`${baseClass}`], {
          [styles[`${baseClass}--invalid`]!]: isCodeInvalid,
        })}
      >
        <Text
          as="h2"
          variant="hl-200"
          className={styles[`${baseClass}__title`]}
        >
          {contentFragment.verificationTitleLabel}
        </Text>
        <Text
          variant="p-200"
          className={styles[`${baseClass}__subtitle`]}
          as={`div`}
        >
          {contentFragment.verificationDescriptionLabel}
        </Text>
        <Text
          variant="p-200"
          className={styles[`${baseClass}__email`]}
          as={`div`}
        >
          {email}
          <span className={styles[`${baseClass}__edit`]} onClick={editEmail}>
            {contentFragment.editButtonLabel}
          </span>
        </Text>

        <CodeInput
          value={otp}
          isInvalid={isCodeInvalid}
          InvalidText={translations.verificationCodeError!}
          onChange={handleCodeInputChange}
          numInputs={6}
          inputType="number"
          renderSeparator={<Spacer width={100} />}
          renderInput={(props, index) => (
            <input
              {...props}
              placeholder={(index + 1).toString()}
              className={clsx(styles[`${baseClass}__input`], {
                [styles[`${baseClass}__input--invalid`]!]: isCodeInvalid,
              })}
              autoComplete="one-time-code"
            />
          )}
        />
        <div className={styles[`${baseClass}__resend`]} onClick={resendCode}>
          {contentFragment.resentCodeTextLabel}
        </div>

        <Button
          variant="primary"
          mode={300}
          className={styles[`${baseClass}__button`]}
          onClick={continueToNextStep}
          disabled={isLoading}
          loading={isLoading}
        >
          {contentFragment.continueButtonLabel}
          <Button.Spinner />
        </Button>

        <Toast
          icon="successful-checkmark"
          open={isOpen}
          onOpenChange={setIsOpen}
          signal="success"
          className={styles[`${baseClass}__toast`]}
        >
          <Toast.Headline>
            {contentFragment.resentCodeToastTitleLabel}
          </Toast.Headline>
          <Toast.Text>{contentFragment.resentCodeToastContentLabel}</Toast.Text>
        </Toast>
      </div>
    </Toast.Provider>
  );
};

export default EmailVerification;
