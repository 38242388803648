export type BooleanToggle = 'true' | 'false';
interface VerifyOtpCodeOptions {
  readonly code: string;
  readonly vToken: string;
  readonly preview: BooleanToggle;
}
export async function verifyOtpCode(
  options: VerifyOtpCodeOptions,
): Promise<any> {
  const {code, vToken, preview} = options;

  const envName = process.env.ENV_NAME;

  if (!envName) {
    throw new Error(`Missing envName`);
  }

  const searchParams = new URLSearchParams({
    envName,
    preview,
  });

  const payload = {
    code,
    vToken,
  };

  const signal = {
    method: `POST`,
    body: JSON.stringify(payload),
  };

  const url = `${
    process.env.BASE_BFF_URL
  }/verify-otp-code?${searchParams.toString()}`;
  const response = await fetch(url, signal);

  if (!response.ok) {
    throw new Error(`Verify otp code failed`);
  }

  return response.json();
}
