import {getCountryCode} from '@smart/locale-service';
import {
  Button,
  Flex,
  IconButton,
  Spacer,
  Text,
  TextArea,
  TextInput,
} from '@smart/react-components';
import {languageSchema, marketIdSchema} from '@smart/types';
import {jsonSafeParse} from '@smart/utils';
import {
  Checkbox,
  Radio,
  Select,
  TextInput as TextInputV1,
} from '@smart/web-components';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {MarketConfigContext} from '../../../context/MarketConfigContext';
import {postRegistration} from '../../../utils/api/post-registration';
import type {BooleanToggle} from '../../../utils/api/types';
import {countryCodeList, countryCodeMap} from '../../../utils/country-code';
import CheckItem from '../PasswordCheck/PasswordCheck';
import styles from './PersonalDetails.module.scss';

interface PersonalDetailsProps {
  email: string;
  setStep: (step: number) => void;
  translations: {[key: string]: string};
  contentFragment: {[key: string]: any};
}

interface PersonalDetailsForm {
  accountType: string;
  companyName?: string;
  firstName: string;
  lastName: string;
  vatNumber?: string;
  countryCode: string;
  phoneNumber?: string;
  password: string;
  marketingConsent?: boolean;
}

export enum CheckState {
  Undefined,
  Success,
  Error,
}

const baseClass = `personal-details`;
const iconClass = styles[`${baseClass}__icon`];
const nameRegexPattern =
  /^[^-\s¡?÷¿\\ˆ~!@#$%^&*()_+\=\[\]{};:"\|\/,.<>?¢0123456789]{2,40}([' -]{1}[^-\s¡?÷¿\\ˆ~!@#$%^&*()_+\=\[\]{};:"\|\/,.<>?¢0123456789]{1,40}){0,2}$/;
const atLeast12Characters = /^.{12,}$/;
const hasUpperAndLowerCase = /^(?=.*[a-z])(?=.*[A-Z]).*$/;
const hasNumber = /^(?=.*\d).*$/;
const hasSpecialCharacter = /^(?=.*[!@#$%^&*(),.?":{}|<>]).*$/;

const PersonalDetails: React.FC<PersonalDetailsProps> = ({
  email,
  setStep,
  translations,
  contentFragment,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [isVatNumberOptional, setIsVatNumberOptional] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [lengthCheck, setLengthCheck] = useState<CheckState>(
    CheckState.Undefined,
  );
  const [upperAndLowerCaseCheck, setUpperAndLowerCaseCheck] =
    useState<CheckState>(CheckState.Undefined);
  const [numberCheck, setNumberCheck] = useState<CheckState>(
    CheckState.Undefined,
  );
  const [specialCharacterCheck, setSpecialCharacterCheck] =
    useState<CheckState>(CheckState.Undefined);

  const marketConfig = useContext(MarketConfigContext);
  const countryCode = getCountryCode(marketConfig.market);

  const selectCodeRef = useRef<HTMLSelectElement>(null);
  const isInitializedRef = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsVatNumberOptional(countryCode === `DE` || countryCode === `GB`);
  }, [countryCode]);

  const {control, handleSubmit, watch, register, setValue} = useForm({
    values: {
      accountType: `private`,
      companyName: ``,
      firstName: ``,
      lastName: ``,
      vatNumber: ``,
      countryCode,
      phoneNumber: ``,
      password: ``,
      marketingConsent: false,
    },
  });

  const input = watch();

  const handleIconClick = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleCodeChange = () => {
    if (!selectCodeRef.current) return;

    const selectedOption =
      selectCodeRef.current.options[selectCodeRef.current.selectedIndex];

    const defaultOption = selectCodeRef.current
      .firstElementChild as HTMLOptionElement;

    defaultOption.textContent = selectedOption?.text?.split(` `)[1] || ``;
    defaultOption.value = selectedOption?.value || ``;
    selectCodeRef.current.selectedIndex = 0;
  };

  useEffect(() => {
    if (!isInitializedRef.current && selectCodeRef.current) {
      const defaultOption = selectCodeRef.current
        .firstElementChild as HTMLOptionElement;

      // Initialize the default option
      defaultOption.selected = true;
      defaultOption.disabled = true;
      defaultOption.hidden = true;

      // Mark as run to prevent re-running
      isInitializedRef.current = true;
    }
    selectCodeRef.current?.addEventListener(`change`, handleCodeChange);

    return () => {
      selectCodeRef.current?.removeEventListener(`change`, handleCodeChange);
    };
  }, []);

  const validatePassword = (password: string) => {
    const isAtLeast12Characters = atLeast12Characters.test(password);
    const hasUpperAndLower = hasUpperAndLowerCase.test(password);
    const hasNum = hasNumber.test(password);
    const hasSpecialChar = hasSpecialCharacter.test(password);

    const isInvalid =
      !isAtLeast12Characters || !hasUpperAndLower || !hasNum || !hasSpecialChar;

    setIsPasswordInvalid(isInvalid);
    setLengthCheck(
      isAtLeast12Characters ? CheckState.Success : CheckState.Error,
    );
    setUpperAndLowerCaseCheck(
      hasUpperAndLower ? CheckState.Success : CheckState.Error,
    );
    setNumberCheck(hasNum ? CheckState.Success : CheckState.Error);
    setSpecialCharacterCheck(
      hasSpecialChar ? CheckState.Success : CheckState.Error,
    );
  };

  const editEmail = () => {
    setStep(1);
  };

  const processOptionalLabel = (label: string) => {
    return isVatNumberOptional ? label.slice(0, -1) : label;
  };

  const onSubmit = (formData: PersonalDetailsForm) => {
    if (isLoading) return;

    const preview = jsonSafeParse(
      localStorage.getItem(`preview`) || `false`,
    ) as BooleanToggle;

    const marketId = marketIdSchema.safeParse(
      marketConfig.market.toLowerCase(),
    );

    const language = languageSchema.safeParse(
      marketConfig.language.toLowerCase(),
    );

    if (!marketId.success) {
      if (process.env.ENV_NAME !== `prod`) {
        console.error({
          errorType: `INVALID_MARKET_ID`,
          message: `Invalid marketId: ${marketConfig.market}`,
        });
      }
      return;
    }

    if (!language.success) {
      if (process.env.ENV_NAME !== `prod`) {
        console.error({
          errorType: `INVALID_LANGUAGE`,
          message: `Invalid language: ${marketConfig.language}`,
        });
      }
      return;
    }

    setIsLoading(true);

    postRegistration(
      {
        language: language.data,
        marketId: marketId.data,
        preview,
      },
      {
        ...formData,
        email,
        phone: `${countryCodeMap.get(formData.countryCode)}${
          formData.phoneNumber
        }`,
      },
    )
      .then(() => {
        navigate(`/login`);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={styles[`${baseClass}`]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text
          as="h2"
          variant="hl-200"
          className={styles[`${baseClass}__title`]}
        >
          {contentFragment.personalDetailsTitleLabel}
        </Text>

        <Flex gap={300}>
          <Radio
            label={translations.private!}
            id={`private`}
            value={`private`}
            {...register(`accountType`, {required: true})}
          />
          <Radio
            label={translations.business!}
            id={`business`}
            value={`business`}
            {...register(`accountType`, {required: true})}
          />
        </Flex>

        <Spacer height={400} />

        <div className={styles[`${baseClass}__email`]}>
          <Text
            variant="p-200"
            as={`div`}
            className={styles[`${baseClass}__email-label`]}
          >
            {translations.emailAddress}
          </Text>
          <Text
            variant="p-200"
            className={styles[`${baseClass}__email-value`]}
            as={`div`}
          >
            {email}
            <span
              className={styles[`${baseClass}__email-edit`]}
              onClick={editEmail}
            >
              {contentFragment.editButtonLabel}
            </span>
          </Text>
        </div>

        {input.accountType === `business` && (
          <>
            <Controller
              name="companyName"
              control={control}
              rules={{
                required: true,
              }}
              render={({field, fieldState}) => (
                <TextInput
                  {...field}
                  className={styles[`${baseClass}__companyName`]}
                  label={translations.companyName!}
                  id="companyName"
                  value={input.companyName}
                  error={fieldState.error && translations.companyNameError}
                  invalid={!!fieldState.error}
                  onBlur={() => {
                    field.onBlur();
                    setValue(field.name, field.value.trim());
                  }}
                />
              )}
            />

            <Spacer height={300} />
          </>
        )}

        <Flex
          direction={{
            lg: `row`,
            xs: `column`,
          }}
          className={styles[`${baseClass}__name`]}
          gap={300}
        >
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: nameRegexPattern,
                message: ``,
              },
            }}
            render={({field, fieldState}) => (
              <TextInput
                {...field}
                className={styles[`${baseClass}__name-firstName`]}
                label={translations.firstName!}
                id="firstName"
                value={input.firstName}
                error={fieldState.error && translations.firstNameError}
                invalid={!!fieldState.error}
                autoComplete="given-name"
                autoCorrect="off"
                onBlur={() => {
                  field.onBlur();
                  setValue(field.name, field.value.trim());
                }}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: nameRegexPattern,
                message: ``,
              },
            }}
            render={({field, fieldState}) => (
              <TextInput
                {...field}
                className={styles[`${baseClass}__name-lastName`]}
                label={translations.lastName!}
                id="lastName"
                value={input.lastName}
                error={fieldState.error && translations.lastNameError}
                invalid={!!fieldState.error}
                autoComplete="family-name"
                autoCorrect="off"
                onBlur={() => {
                  field.onBlur();
                  setValue(field.name, field.value.trim());
                }}
              />
            )}
          />
        </Flex>
        {/**optional in DE and UK */}
        {input.accountType === `business` && (
          <>
            <Spacer height={300} />
            <Controller
              name="vatNumber"
              control={control}
              rules={{
                required: !isVatNumberOptional,
              }}
              render={({field, fieldState}) => (
                <TextInput
                  {...field}
                  className={styles[`${baseClass}__vatNumber`]}
                  label={processOptionalLabel(translations.vatNumber!)}
                  id="vatNumber"
                  value={input.vatNumber}
                  error={fieldState.error && translations.vatNumberError}
                  invalid={!!fieldState.error}
                  onBlur={() => {
                    field.onBlur();
                    setValue(field.name, field.value.trim());
                  }}
                />
              )}
            />
          </>
        )}
        <Spacer height={300} />
        <Flex gap={100}>
          <Controller
            name="countryCode"
            control={control}
            render={({field}) => (
              <Select
                {...field}
                className={styles[`${baseClass}__phone-code`]}
                id={`Country code`}
                label={translations.countryCode!}
                value={input.countryCode}
                options={[
                  {
                    label: countryCodeMap.get(countryCode)!,
                    value: countryCode,
                  },
                  ...countryCodeList,
                ]}
                ref={selectCodeRef}
                onChange={(e) => {
                  setValue(field.name, e.target.value);
                }}
              />
            )}
          />
          <Controller
            name="phoneNumber"
            control={control}
            rules={{
              pattern: {
                value: /^[0-9]{3,13}$/,
                message: ``,
              },
            }}
            render={({field, fieldState}) => (
              <TextInputV1
                {...field}
                className={styles[`${baseClass}__phone-number`]}
                type="tel"
                autoComplete="tel-national"
                label={translations.phoneNumber!}
                id="phoneNumber"
                value={input.phoneNumber}
                message={fieldState.error && translations.phoneNumberError}
                isInvalid={!!fieldState.error}
                onBlur={() => {
                  setValue(field.name, field.value.trim());
                }}
              />
            )}
          />
        </Flex>

        <Spacer height={300} />

        <Controller
          name="password"
          control={control}
          rules={{
            required: true,
          }}
          render={({field, fieldState}) => (
            <TextInput
              {...field}
              className={styles[`${baseClass}__password`]}
              type={passwordVisible ? `text` : `password`}
              autoComplete={`new-password`}
              autoCorrect={`off`}
              label={translations.password!}
              id="password"
              value={input.password}
              error={
                (fieldState.error || isPasswordInvalid) &&
                translations.passwordError
              }
              invalid={!!fieldState.error || isPasswordInvalid}
              trailing={
                <IconButton
                  variant="ghost"
                  onClick={handleIconClick}
                  className={iconClass}
                >
                  <Button.Icon
                    icon={passwordVisible ? `eye-off` : `eye`}
                    aria-label={
                      passwordVisible ? `hide password` : `show password`
                    }
                  />
                </IconButton>
              }
              onChange={(e) => {
                setValue(field.name, e.target.value.trim());
                validatePassword(e.target.value.trim());
              }}
            />
          )}
        />
        <Spacer height={300} />

        <Flex gap={100} direction={`column`}>
          <CheckItem
            checkState={lengthCheck}
            translationKey="lengthCheck"
            translations={translations}
          />
          <CheckItem
            checkState={upperAndLowerCaseCheck}
            translationKey="upperAndLowerCaseCheck"
            translations={translations}
          />
          <CheckItem
            checkState={numberCheck}
            translationKey="numberCheck"
            translations={translations}
          />
          <CheckItem
            checkState={specialCharacterCheck}
            translationKey="specialCharacterCheck"
            translations={translations}
          />
        </Flex>

        <Controller
          name="marketingConsent"
          control={control}
          render={({field, fieldState}) => (
            <Checkbox
              className={styles[`${baseClass}__checkbox`]}
              value={``}
              label={contentFragment.marketingTextLabel!}
              id="marketing-consent"
              isInvalid={!!fieldState.error}
              onChange={(e) => {
                setValue(field.name, e.target.checked);
                field.onChange(e.target.checked);
              }}
            />
          )}
        />

        {input.marketingConsent && (
          <TextArea
            className={styles[`${baseClass}__consent`]}
            caption=""
            label=""
            readOnly
            value={contentFragment.privacyStatementLabel.markdown}
          />
        )}

        <TextArea
          className={styles[`${baseClass}__consent`]}
          caption=""
          label=""
          readOnly
          value={contentFragment.marketingConsentLabel.markdown}
        />

        <Button
          mode={300}
          type="submit"
          variant="primary"
          className={styles[`${baseClass}__button`]}
          disabled={isLoading}
          loading={isLoading}
        >
          {contentFragment.createAccountButtonLabel}
          <Button.Spinner />
        </Button>
      </form>
    </div>
  );
};

export default PersonalDetails;
