import React, {createContext, useContext, useState} from 'react';

interface AuthContextType {
  uid: string | null;
  vToken: string | null;
  regToken: string | null;
  setAuth: (auth: {uid?: string; vToken?: string; regToken?: string}) => void;
  clearAuth: () => void;
}

const initialState: AuthContextType = {
  uid: null,
  vToken: null,
  regToken: null,
  setAuth: () => {},
  clearAuth: () => {},
};

const AuthContext = createContext<AuthContextType>(initialState);

export const AuthProvider: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const [auth, setAuthState] = useState(initialState);

  const setAuth = (newAuth: {
    uid?: string;
    vToken?: string;
    regToken?: string;
  }) => {
    setAuthState((prev) => ({
      ...prev,
      ...newAuth,
    }));
  };

  const clearAuth = () => {
    setAuthState(initialState);
  };

  const value = {
    ...auth,
    setAuth,
    clearAuth,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuth must be used within AuthProvider`);
  }
  return context;
};
