import {Icon, Spacer, Text} from '@smart/react-components';
import React from 'react';
import OtpInput from 'react-otp-input';
import styles from './CodeInput.module.scss';

interface CodeInputProps {
  value: string;
  isInvalid: boolean;
  InvalidText: string;
  onChange: (value: string) => void;
  numInputs: number;
  inputType: `number` | `password` | `tel` | `text`;
  renderSeparator?: React.ReactNode;
  renderInput: (props: any, index: number) => React.ReactNode;
}

const baseClass = `conde-input`;

const CodeInput: React.FC<CodeInputProps> = (props) => {
  const {
    value,
    isInvalid,
    InvalidText,
    onChange,
    numInputs,
    inputType,
    renderSeparator,
    renderInput,
  } = props;

  return (
    <>
      <OtpInput
        value={value}
        onChange={onChange}
        numInputs={numInputs}
        inputType={inputType}
        renderSeparator={renderSeparator}
        renderInput={renderInput}
      />
      {isInvalid && (
        <div className={styles[`${baseClass}__warn`]}>
          <Icon icon="warning" mode={200} />
          <Spacer width={100} />
          <Text variant="cap-300">{InvalidText}</Text>
        </div>
      )}
    </>
  );
};

export default CodeInput;
