// core packages
import type {IFeatureFlagsContext} from '@smart/feature-app';
import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import ConsentRegister from '../../components/ConsentRegister/ConsentRegister';
import {WithLayout} from '../../components/Layout/WithLayout';
import {WithLayoutV2} from '../../components/LayoutV2/WithLayoutV2';
import NoMarket from '../../components/NoMarket/NoMarket';
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader';
import {AemContentContext} from '../../context/AemContentContext';
import {FeatureFlagsContext} from '../../context/FeatureFlagsContext';
import {MarketConfigContext} from '../../context/MarketConfigContext';
import type {AemContent} from '../../lambda/aem/types';
import {UsedFeatureFlags} from '../../utils/configs';

const getRegisterComponent = (market: string) => {
  if (market === `UNKNOWN`) {
    return WithLayout(<NoMarket />);
  }

  return WithLayout(
    <ScreenLoader
      screenSet="smart-RegistrationLogin"
      startScreen="gigya-register-screen"
    />,
  );
};

export const Register = () => {
  const [isNewRegistrationEnabled, setIsNewRegistrationEnabled] =
    React.useState(false);
  const marketConfig = React.useContext(MarketConfigContext);
  const AemContent = React.useContext(AemContentContext);
  const {translations, registrationContentFragment} =
    AemContent.data as AemContent;

  const featureFlagsContext = React.useContext(FeatureFlagsContext);
  const {isLoading} = featureFlagsContext;
  const {featureFlags} = featureFlagsContext.data as IFeatureFlagsContext;

  const RegisterComponent = getRegisterComponent(marketConfig.market);
  const ConsentRegisterComponent = WithLayoutV2(
    <ConsentRegister
      translations={translations}
      contentFragment={registrationContentFragment}
    />,
  );

  useEffect(() => {
    if (!isLoading && featureFlags) {
      const value = featureFlags?.flags.find(
        (item) =>
          item.name === UsedFeatureFlags.ENABLE_B2C_REGISTRATION_CONSENT &&
          item.status === `valid`,
      );

      setIsNewRegistrationEnabled(!!value);
    }
  }, [isLoading, featureFlags]);

  return (
    <>
      <Helmet>
        <title>Registration</title>
        <meta name="description" content="Registration" />
      </Helmet>
      <div className="register">
        {isNewRegistrationEnabled ? (
          <ConsentRegisterComponent />
        ) : (
          <RegisterComponent />
        )}
      </div>
    </>
  );
};

export default Register;
