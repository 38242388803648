import React from 'react';
import ErrorCodeNotificationDisplay from '../ErrorCodeNotificationDisplay/ErrorCodeNotificationDisplay';
import LayoutV2 from './LayoutV2';

export const WithLayoutV2 = (Component: JSX.Element) => () => {
  return (
    <LayoutV2>
      <ErrorCodeNotificationDisplay />
      {Component}
    </LayoutV2>
  );
};
