import type {Language} from '@smart/types';
import type {AemContent} from '../../lambda/aem/types';
import type {BooleanToggle, FetchCommonOptions} from './types';

interface FetchAemOptions extends FetchCommonOptions {
  readonly preview: BooleanToggle;
  readonly signal: AbortSignal;
  readonly language: Language;
}
export async function fetchAem(options: FetchAemOptions): Promise<AemContent> {
  const {marketId, preview, signal, language} = options;

  const envName = process.env.ENV_NAME;

  if (!envName) {
    throw Error(`Missing "envName"`);
  }

  const searchParams = new URLSearchParams({
    envName,
    marketId,
    preview,
    language,
  });

  const url = `${process.env.BASE_BFF_URL}/aem?${searchParams.toString()}`;
  const response = await fetch(url, {signal});

  if (!response.ok) {
    throw Error(`Could not fetch AEM content`);
  }

  return response.json() as Promise<AemContent>;
}
