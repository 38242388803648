export type BooleanToggle = 'true' | 'false';
interface sendOtpCodeOptions {
  readonly lang: string;
  readonly email: string;
  readonly preview: BooleanToggle;
}
export async function sendOtpCode(options: sendOtpCodeOptions): Promise<any> {
  const {lang, email, preview} = options;

  const envName = process.env.ENV_NAME;

  if (!envName) {
    throw new Error(`Missing "envName"`);
  }

  const searchParams = new URLSearchParams({
    lang,
    email,
    envName,
    preview,
  });

  const url = `${
    process.env.BASE_BFF_URL
  }/send-otp-code?${searchParams.toString()}`;

  const response = await fetch(url, {
    method: `GET`,
  });

  if (!response.ok) {
    throw new Error(`Send otp code failed`);
  }

  return response.json();
}
