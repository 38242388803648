import {Flex} from '@smart/react-components';
import React, {useState} from 'react';
import {AuthProvider} from '../../context/RegisterAuthContext';
import styles from './ConsentRegister.module.scss';
import EmailEntry from './EmailEntry/EmailEntry';
import EmailVerification from './EmailVerification/EmailVerification';
import PersonalDetails from './PersonalDetails/PersonalDetails';

const baseClass = `register`;
interface ConsentRegisterProps {
  translations: {[key: string]: string};
  contentFragment: {[key: string]: string};
}

const ConsentRegister: React.FC<ConsentRegisterProps> = (props) => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState(``);

  const {translations, contentFragment} = props;

  return (
    <AuthProvider>
      <Flex className={styles.register}>
        <div className={styles[`${baseClass}__left`]}>
          {step === 1 && (
            <EmailEntry
              email={email}
              setEmail={setEmail}
              setStep={setStep}
              translations={translations}
              contentFragment={contentFragment}
            />
          )}
          {step === 2 && (
            <EmailVerification
              email={email}
              setStep={setStep}
              translations={translations}
              contentFragment={contentFragment}
            />
          )}
          {step === 3 && (
            <PersonalDetails
              email={email}
              setStep={setStep}
              translations={translations}
              contentFragment={contentFragment}
            />
          )}
        </div>
        <div className={styles[`${baseClass}__right`]}></div>
      </Flex>
    </AuthProvider>
  );
};

export default ConsentRegister;
