import {countries} from 'countries-list';

interface CountryCode {
  label: string;
  value: string;
}

const countryEntries = Object.entries(countries);

export const countryCodeList: CountryCode[] = countryEntries.map(
  ([alpha2, details]) => ({
    label: `${alpha2} +${details.phone[0]}`,
    value: alpha2,
  }),
);

export const countryCodeMap: Map<string, string> = new Map(
  countryEntries.map(([alpha2, details]) => [alpha2, `+${details.phone[0]}`]),
);
