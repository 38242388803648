import {Flex, Icon, Text} from '@smart/react-components';
import React from 'react';
import {CheckState} from '../PersonalDetails/PersonalDetails';

interface CheckItemProps {
  checkState: CheckState;
  translationKey: string;
  translations: {[key: string]: string};
}

const CheckItem: React.FC<CheckItemProps> = ({
  checkState,
  translationKey,
  translations,
}) => (
  <Flex gap={100} alignItems="center">
    <Icon
      icon={
        checkState === CheckState.Success ? `success-filled` : `status-good`
      }
      color={checkState === CheckState.Success ? `sig-success` : undefined}
      mode={200}
    />
    <Text
      variant="p-100"
      color={checkState === CheckState.Error ? `sig-error` : `c-high-contrast`}
    >
      {translations[translationKey]}
    </Text>
  </Flex>
);

export default CheckItem;
