import {Icon, Logo, Text} from '@smart/react-components';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {MarketConfigContext} from '../../context/MarketConfigContext';
import styles from './HeaderV2.module.scss';

function HeaderV2() {
  const header = React.useContext(MarketConfigContext);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <header className={styles[`header`]}>
      <div onClick={handleClick} className={styles[`header__back`]}>
        <Icon icon="arrow-left" mode={300} />
        <Text className={styles[`header__text`]} variant="p-200">
          Back{/*TODO: Back text should in MarketConfig */}
        </Text>
      </div>

      <a
        className={styles[`header__link`]}
        href={header.logo.href}
        aria-label={header.logo.label}
      >
        <Logo
          aria-label={header.logo.label}
          className={styles[`header__logo`]}
          logo="smart-logo-wordmark"
          label={header.logo.label}
        />
      </a>
    </header>
  );
}

export default HeaderV2;
