import {Spinner} from '@smart/react-components';
import clsx from 'clsx';
import type {PropsWithChildren} from 'react';
import React from 'react';
import {AemContentContext} from '../../context/AemContentContext';
import {FeatureFlagsContext} from '../../context/FeatureFlagsContext';
import Footer from '../Footer/Footer';
import HeaderV2 from '../HeaderV2/HeaderV2';
import './LayoutV2.scss';

const LayoutV2 = ({children}: PropsWithChildren) => {
  const aemContent = React.useContext(AemContentContext);
  const {isLoading: aemIsLoading} = aemContent;

  const featureFlagsContext = React.useContext(FeatureFlagsContext);
  const {isLoading} = featureFlagsContext;
  const baseClass = `layout`;

  return (
    <div className={baseClass}>
      <HeaderV2 />
      <div className={`${baseClass}__wrapper`}>
        <main
          className={clsx(
            `${baseClass}__content${aemIsLoading ? `--center` : ``}`,
          )}
        >
          <Spinner size={`xl`} loading={aemIsLoading || isLoading}>
            {children}
          </Spinner>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default LayoutV2;
